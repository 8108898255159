import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CardListing from '../components/CardListing';
import PressReleaseCard from '../components/PressReleaseCard';
import ModularBlocks from '../components/ModularBlocks';

const PressReleasesPage = () => {
  const {
    datoCmsPressReleasesArchive: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
    },
    allDatoCmsPressRelease: { nodes: posts },
  } = useStaticQuery(graphql`
    query PressReleasesPageQuery {
      datoCmsPressReleasesArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        bannerImage {
          ...SubpageBannerImageFragment
        }
        modularBlocks {
          ...ContentModularBlockFragment
        }
      }
      allDatoCmsPressRelease(
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          ...PressReleaseQueryFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} image={bannerImage} />
        <CardListing>
          {posts.map(({ title, slug, bannerImage }) => (
            <PressReleaseCard
              title={title}
              slug={slug}
              image={bannerImage}
              inverted={true}
            />
          ))}
        </CardListing>
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default PressReleasesPage;
